@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* HEADER DESIGNING */
.React-App{
 font-family: Montserrat; 
}
#top-bar{
  width:100rem;
  height: 1rem;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: #F5F5F5;
  box-shadow:0rem 0.1rem 0.2rem #00000029;
  
}
#hashedin-logo{
  background-image: url(/src/Components/assets/Logo-2.png);
  flex-basis: 78%;
  height: 1.5rem;
  width: 4rem;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 76rem;
 
}
#courses-top-bar{
  flex-basis: 8%;
  font-weight: 900;
}
#mywishlist-top-bar{
  flex-basis: 8%;
}
#cart-img{
  flex-basis:3%;
  background-image: url(/src/Components/assets/shopping-cart.svg);
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 2rem;
}
#profile-icon{
  flex-basis:3%;
  background-image: url(/src/Components/assets/noun_profile_2068277.svg);
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 2rem;
  margin-right: 1rem;
  color:#F5F5F5;
}
a{
  margin-right: 0.1rem;
  margin-left: 0.4rem;
}
a:-webkit-any-link{
  text-decoration:none;
  color: #000000;
}


/* TAGLINE DIV DESIGNING */
#tagline-background{
  background: transparent;
  background-image: url(/src/Components/assets/Mask\ Group\ 1.png);
  background-color: #0F1317;
  background-size: 76rem 7rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 12rem;
  margin-right: 12rem;
  width: 76rem;
  height: 7rem;
  background-repeat: no-repeat;
  border-radius: 0.4rem;
}
#tagline-text,#tagline-course,#tagline-text-profile{
  color: #F5F5F5;
  font-size: large;
  padding: 1rem;
  margin-left: 2rem;
}
#tagline-text{
  padding-bottom: 0rem;

}
#tagline-course{
  padding-top: 0rem;

}
#tagline-text-profile{
  padding-top: 2.5rem;

}

/* COURSE AREA STYLING */
#course-area{
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 12rem;
  margin-right: 12rem;
  width: 76rem;
  height: 40rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
}

/* COURSE DISPLAY STYLING */
#course{
  display: flex;
  flex-direction: column;
}
#course-top-bar{
  display: flex;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#course-top-bar-text{
  flex-basis: 80%;
  font-weight: bolder;
  letter-spacing: 0.1rem;
}
#course1{
  display: flex;
  flex-direction: column;
  box-shadow:0rem 0.25rem 0.5rem #00000029;
  margin-left:0.3rem;
  margin-top:1.5rem;
  margin-right:2.5rem;
  
}

#course-top-bar-text1{
  flex-basis: 80%;
  font-weight: bolder;
  opacity: 0.5;
}
#sorter{
  flex-basis: 20%;

}

/* SINGLE COURSE STYLING */
#single-course{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow:0rem 0.25rem 0.5rem  #00000029;
  border-radius: 0rem 0rem 0.4rem 0.4rem;
  width:90%;
  height: 5rem;
  margin:0.2rem;
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  padding-left: 1rem;
  
}

#course-author,#course-author1,#move-to-wishlist,#course-wishlist,#course-delete,#original-price,#discounted-price,#add-to-cart,#next-page,#course-wishlist1{

  width: 100%;
  align-self: center;
  
}
 


#course-pic{
  width: 4rem;
  align-self: center;
  flex-basis: 8%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 0.1rem;
  background-color: #F2F2F2;
  margin-right: 0.3rem;
  
}
#course-title{
  flex-basis: 33%;
  height: contain;
  display: grid;
  grid-template-rows: 2fr 1fr;
}
#course-name{
  height: 100%;
  font-weight: bold;
}
#course-tags{
  height: 100%;
  display: flex;
  flex-direction: row;
}
#tag-design{
  height: 1.2rem;
  background-color: #FF6738;
  color: #F5F5F5;
  border-radius: 0.2rem;
  text-align: center;
  font-size: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  margin-bottom: 0.1rem;

}
#course-author{
  flex-basis: 15%;
  height: 2rem;
  margin-left: 1rem;
  margin-right: 0.1rem;
  font-weight: 600;
  opacity: 0.6;
}
  #course-author1{
    flex-basis: 23%;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 0.1rem;
    font-weight: 600;
    opacity: 0.6;  
  }

#course-wishlist{
  flex-basis: 3%;
  height: 2rem;
  background-image: url(./Components/assets/Path\ 28-1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
 
}
#course-wishlist1{
  flex-basis: 3%;
  height: 2rem;
  background-image: url(./Components/assets/Path\ 28.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
 
}
#course-delete{
  flex-basis: 3%;
  height: 1.5rem;
  background-image: url(./Components/assets/noun_Delete_3715928.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
 
}

#original-price{
  flex-basis: 10.5%;
  height: 2rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  font-weight: bold;
}
#discounted-price{
  flex-basis: 9.5%;
  height: 2rem;
  opacity: 0.7;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  text-decoration: line-through;
 
}
#add-to-cart{
  flex-basis: 15%;

}
#next-page{
  flex-basis: 3%;
  height: 1.5rem;
  background-image: url(./Components/assets/Path\ 20-1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  
}

#button-addtocart
{
  background-color: #FF6738;
  border-radius: 0.3rem;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.1rem;
 
}
#move-to-wishlist{
  flex-basis: 40%;
  color: #1873CE;
  text-align: end;
  padding-right: 2rem;
}

/* SEARCH AREA DESIGN  */
#search-area{
  width:100%;
  height: 34.5rem;
  box-shadow:0rem 0.25rem 0.5rem  #00000029;
}
#search-bar{
  display: flex;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/* SEARCH BAR  */

#search-here{
  flex-basis: 90%;
  width: 100%;
  height:1.5rem;
  box-sizing: border-box;
  border: none;
  background-color:#EFF2FF;
  color: #444f5a;
  margin-left: -0.8rem;
}
#search-icon{
  flex-basis: 10%;
  background-image: url(/src/Components/assets/Group\ 35.svg);
  background-color: #FF6738;
  background-repeat: no-repeat;
  background-position: center;
  width: 1rem;
  height: 1.5rem;
  background-size: 1rem 1.5rem;
  
}

/* CART AREA  */
#search-pannel{
  display: grid;
  height: 31rem;
  grid-template-rows: 4fr 35fr 8fr;
}
#cart-heading{
  margin-right: 1rem;
  margin-left: 1rem;
  width: 90%;
  text-align: center;
  box-shadow:0rem 0.25rem 0rem #00000029;
  font-weight: 500;
  opacity: 80%;
}
#cart-material{
  padding: 1rem;
  overflow: scroll;
  color: darkgrey;
  font-style: italic;
  font-size: small;
}
#side-cart-footer{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#total-cart-value{
  display: grid;
  grid-template-rows: 1fr 2fr;
  box-shadow: none;
   
}
#total-text{
  padding-left: 1rem;
  font-weight: 500;
  font-size: small;
  box-shadow: none;
}
#total-cart-price{
  padding-left: 1rem;
  font-weight: bolder;
  font-size: x-large;
  box-shadow:0rem 0.25rem 0.5rem #00000029; 
}
#checkout-button-cart{
  color:#FF6738;
  font-weight: bolder;  
  align-self: center;
  font-size: small;
  padding-top: 2.2rem;
  
}

/* COURSE IN CART  */
#course-in-cart{
  display: grid;
  grid-template-rows: 3fr 1fr;
  box-shadow:0rem 0.1rem 0rem #00000029;
  height: 5rem;
}
#course-in-cart-title{
  font-style: normal;
  font-weight: 600;
  color: #0F1317;
  display: flex;
  flex-direction: row;
}
#course-in-cart-pic{
  flex-basis: 20%;
  padding-top: 0.3em;
  padding-bottom: 0.3rem;
  background-color: #F2F2F2;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  margin-top: 0.4rem;
  width: 2rem;
  height: 2.2rem;
}
#cart-course-name{
  flex-basis:80%;
}
#course-cart-price{
  font-style: normal;
  font-weight: bolder;
  font-size: large;
  color: #0F1317;
  text-align: end;
  margin-right: 1rem;
}

/* PROFILE PAGE DESIGNING  */
#profile-area{
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 12rem;
  margin-right: 12rem;
  width: 76rem;

  display: grid;
  grid-template-columns: 1fr 4fr;

}

#profile-pic{
  background-image: url(./Components/assets/Mask\ Group\ 2.png);
  margin-left: 1rem;
  margin-right: 1rem;
  width: 85%;
  height: 12rem;
  background-size: contain;
  background-repeat: no-repeat;
}

#profile-container{
  display: grid;
  grid-template-rows: 1fr 3fr 1.5fr 1fr 1.5fr 1fr ;
  
}

/* DIV STYLING OF PROFILE CONTAINER  */

#profile-names,#about-yourself,#area-of-intrest,#stud-or-prof,#more-info,#form-end{
  padding-left: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

}


#profile-names{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: space-around;
 }
#display-name,#first-name,#last-name{
  width: 13rem;
}
#about-yourself{
  width:60%;
}
#more-info{
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.profile-text-design{
  font-weight: bolder;
  opacity: 0.5;
  font-size: 0.8rem;
}
#area-of-intrest{
  width: 19%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  height: fit-content;
 }
 #intrest-option {
   color:#2c2626;
   font-size: 0.92rem;
   height: 1.5rem;
 }
 #stud-or-prof{
   display: grid;
   grid-template-rows: 1fr 1fr;
 }
 #sp-opn{
  font-size: 0.95rem;
  height: 1.2rem;
 }
 #experience{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
 }
 #exp-opn{
   width: 10rem;
  
 }
 #expertise{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
 }
 #ext-on{
  width: 10rem;
  
 }

 #role{
   width:30%;
 }
 #role-input{
    width:25rem;
    height: 1.8rem;
 }
/* INPUT STYPLING IN PROFILE PAGE  */
#name-input, #about-yourself-input, #role-input{
  box-sizing: border-box;
  border: none;
  background-color:#EFF2FF;
  color: #444f5a;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding-left: 1rem;
}

#name-input{
  width: 100%;
  height:1.5rem;
}
#about-yourself-input{
  width: 100%;
  height: 80%;
  text-align: start;
}
#name-input{
  width: 75%;
  height:1.5rem;
}

#form-end{
  justify-self: end;
}



#form-end{
  background-color: #FF6738;
  border-radius: 0.3rem;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.1rem;
  width: 4rem;
  height: 1.3rem;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color:#FF6738;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='checkbox']:after {
  width: 15px;
  height: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='checkbox']:checked:after {
  width: 15px;
  height: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color:#FF6738;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}


/* CART SIDEBAR DESGINING  */
#checkout-side-area{
  width:100%;
  height: 10rem;
  box-shadow:0rem 0.25rem 0.5rem  #00000029;
  display: grid;
  grid-template-rows: 1fr 2fr 0.5fr 2fr;
  padding-left: 1rem;
  margin-top: 3.8rem;
}

#cart-text{
  opacity: 0.7;
  font-size: medium;
}
#cart-ta{
  font-weight: bolder;
  font-size: xx-large;
}
#cart-sa{
  color:#629A33;
  font-size: small;
}
#cart-btn{
  background-color: #FF6738;
  border-radius: 0.3rem;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 0.1rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  width: 86%;
  height: 1.3rem;

}

/* COURSE DESIGN */

#design-top-bar {
  display: flex;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0px;
  background-repeat: no-repeat;
}
#sub-course {
  flex-basis: 20%;
  font-weight:bolder;
  text-decoration: black;
  font-weight: bolder;
  font-size: larger;
  
}
#arrow-image {
  flex-basis: 2%;
  background-repeat :no-repeat;
  background-image :url('./Components/assets/Path\ 20.svg');
  background-repeat :no-repeat; 
  margin-top: 0.35rem;
}
#all-course{
  flex-basis: 11%;
  margin-top: 0.3rem;
  margin-left: 11rem;
}


#top-bar-div{
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-position: right;
  background-color: #0f1317;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  width: 100rem;
  height: 15rem;
  padding-left: 8rem;
  
}

#course-disc {
  color: #F5F5F5;
  padding: 1rem;
  padding-bottom: 0rem;
  margin-left: 3rem;
  font-size: 1.5rem;
}
#course-mini {
  color: #F5F5F5;
  padding: 1rem;
  padding-bottom: 0rem;
  margin-left: 3rem;
  font-size: 0.8rem;
}
#course-author2 {
  color: #FF9271;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
  margin-left: 4rem;
  font-size: 0.8rem;
  margin-top: 1rem;
  opacity: 1;
}
#tags-course {
  display: flex;
  flex-direction: row;
}
#react-tag{
  color: black;
  margin-left: 4rem;
  margin-top: 1rem;
}
#video{
  margin-top: 5rem;
  width: 35rem;
  height: 20rem;
}
#react-tagq{
  color: black;
  margin-left: 4rem;
  margin-top: 1rem;
  display: flex;
}

#full-course-descp {
  flex-basis: 40%;
  padding-top: 1rem;;
  border: 0.1rem solid #E0E0E0;
  margin-top: 0.8rem;
  width: 50rem;
  margin-left: 8rem;
  height: 20rem;
  padding-left: 0.8rem;
  opacity: 1;
  color: #000000;
}



#price-time {
  flex-basis: 30%;
  border: 0.1rem solid #E0E0E0;
  margin-left: 2rem;
  margin-top: 8rem;
  width: 43rem;
}
.original-price {
  margin-left: 2rem;
  margin-top: 1.5rem;
  font: normal normal bold 30px/37px Montserrat;
}
.discounted-price {
  margin-top:0.2rem;
  margin-left: 2rem;
  text-decoration: line-through;
}
#time-remaining{
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-left: 2rem;
  color: #D13030;
  opacity: 1;
}
#clock {
  flex-basis: 8%;
  background-image: url('./Components/assets/clock.svg');
  background-repeat: no-repeat;
  height: 1.5rem;
  padding-bottom: 0.5rem;
}
#cart-wishlist {
  display: flex;
  flex-direction: row;
}
#add-to-cart-design {
  background-color:  #FF6738;
  margin-left: 2rem;
  opacity: 1;
  margin-top: 1rem;
  color: #FFFFFF;
  font-size: 0.8rem;
  text-align: center;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}
#wishlisted {
  border: 0.1rem solid #FF6738;
  margin-top: 1rem;
  margin-left: 2rem;
  color:#FF6738;
  font-size: 0.6rem;
  text-align: center;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  background-image: url('./Components/assets/wish-list.svg');
  background-repeat: no-repeat;
  background-position :right;
  width: 7rem;
}
